import type { BreadcrumbsLinks } from '~types/props'

import type { GUID } from '@arora/common'

function buildBreadcrumbs(name: string | undefined, path: string, meta: any): void {
  const links = [] as BreadcrumbsLinks[]

  const appConfig = useAppConfig()
  const clientStore = useClientStore()
  const menuStore = useMenuStore()

  clientStore.BreadcrumbBackLink = null

  menuStore.setGroupIdManually(
    meta.GroupID ? (meta.GroupID as GUID) : null,
    meta.SubgroupID ? (meta.SubgroupID as GUID) : null
  )

  switch (name) {
    case 'about':
      links.push({
        Name: 'breadcrumbs.about',
        Link: appConfig.VueSettingsPreRun.Links.AboutLink
      })
      break
    case 'actions-list':
      links.push({
        Name: 'breadcrumbs.actionsList',
        Link: appConfig.VueSettingsPreRun.Links.ActionsLink
      })
      break
    case 'actions-id':
      links.push(
        {
          Name: 'breadcrumbs.actionsList',
          Link: appConfig.VueSettingsPreRun.Links.ActionsLink
        },
        {
          Name: 'breadcrumbs.actionSingle',
          Link: path
        }
      )
      clientStore.BreadcrumbBackLink = appConfig.VueSettingsPreRun.Links.ActionsLink
      clientStore.BreadcrumbBackText = 'breadcrumbs.actionsBack'
      break
    case 'articles-list':
      links.push({
        Name: 'breadcrumbs.articlesList',
        Link: appConfig.VueSettingsPreRun.Links.ArticlesLink
      })
      break
    case 'restaurants-list':
      links.push({
        Name: 'breadcrumbs.restaurantsList',
        Link: appConfig.VueSettingsPreRun.Links.RestaurantsLink
      })
      break
    case 'restaurants-id':
      links.push(
        {
          Name: 'breadcrumbs.restaurantsList',
          Link: appConfig.VueSettingsPreRun.Links.RestaurantsLink
        },
        {
          Name: 'breadcrumbs.restaurantSingle',
          Link: path
        }
      )
      clientStore.BreadcrumbBackLink = appConfig.VueSettingsPreRun.Links.RestaurantsLink
      clientStore.BreadcrumbBackText = 'breadcrumbs.restaurantsBack'
      break
    default:
      if (name) {
        if (name.includes('article|')) {
          links.push(
            {
              Name: 'breadcrumbs.articlesList',
              Link: appConfig.VueSettingsPreRun.Links.ArticlesLink
            },
            {
              Name: 'breadcrumbs.articleSingle',
              Link: path
            }
          )
          clientStore.BreadcrumbBackLink = appConfig.VueSettingsPreRun.Links.ArticlesLink
          clientStore.BreadcrumbBackText = 'breadcrumbs.articlesBack'
        }
        if (name.includes('group|') || name.includes('product|')) {
          links.push({
            Name: 'breadcrumbs.menu',
            Link: appConfig.VueSettingsPreRun.Links.MenuLink
          })

          if (name.includes('product|') && meta.GroupLink.length > 0) {
            links.push({
              Name: '',
              MetaName: meta.GroupTitle,
              Link: meta.GroupLink
            })

            if (!appConfig.VueSettingsPreRun.BreadcrumbsShown) {
              clientStore.BreadcrumbBackLink = meta.GroupLink
              clientStore.BreadcrumbBackText = 'breadcrumbs.menuBack'
            }
          }

          links.push({
            Name: '',
            MetaName: meta.Title,
            Link: path
          })
        }
      }
      break
  }

  if (links.length > 0 && appConfig.VueSettingsPreRun.BreadcrumbsShowMain) {
    links.unshift({
      Name: 'breadcrumbs.mainPage',
      Link: appConfig.VueSettingsPreRun.Links.MainLink
    })
  }

  clientStore.Breadcrumbs = links
}

export default defineNuxtRouteMiddleware((to, from) => {
  buildBreadcrumbs(to.name?.toString(), to.path, to.meta)

  if (to.path !== from.path && import.meta.client) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
})
