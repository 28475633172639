<template>
  <div
    class="v-swiper-wrapper"
    :class="className"
  >
    <div
      v-if="enableNavigation"
      class="v-btn-custom-swiper"
    >
      <div
        class="swiper-button-prev"
        :class="`${uid}-swiper-button-prev`"
      />
      <div
        class="swiper-button-next"
        :class="`${uid}-swiper-button-next`"
      />
    </div>
    <swiper
      :active-index="activeIndex"
      :auto-height="autoHeight"
      :autoplay="autoplayDelay > 0 ? autoplay : false"
      :breakpoints="breakpoints"
      :centered-slides="centered"
      :coverflow-effect="effect === 'coverflow' ? coverflow : undefined"
      :direction="horizontal ? 'horizontal' : 'vertical'"
      :effect="effect"
      :free-mode="enableFreeMode ? freeMode : undefined"
      :initial-slide="activeIndex !== 0 ? activeIndex : initialSlide"
      :loop="loop"
      :modules="modules"
      :mousewheel="!horizontal"
      :navigation="enableNavigation ? navigation : false"
      :pagination="paginationType !== 'none' ? pagination : false"
      :preload-images="false"
      :scrollbar="enableScrollbar ? scrollbar : undefined"
      :slides-per-view="maxItems"
      :space-between="spaceBetween"
      :thumbs="thumbs"
      @slideChange="slideChange"
      @swiper="setSwiperReference"
      @touchEnd="touchEnd"
      @touchStart="touchStart"
    >
      <swiper-slide
        v-for="(item, index) in itemsForSlide"
        :key="typeof item === 'object' && 'ID' in item ? item.ID : `${item}-${index}`"
        @click="() => slideClick(index)"
      >
        <slot
          v-if="$slots.item"
          name="item"
          v-bind="item"
        />
        <slot
          v-else-if="$slots.indexedItem"
          name="indexedItem"
          v-bind="{ item: item, index: index }"
        />
      </swiper-slide>
    </swiper>

    <div
      v-if="paginationType !== 'none'"
      :id="`${uid}-v-pagination-swiper`"
      class="swiper-pagination"
    />
  </div>
  <swiper
    v-if="thumbsSlidesItems > 0"
    class="v-swiper-thumbs"
    free-mode
    watch-slides-progress
    :loop="loop"
    :modules="enableFreeMode ? modules : [FreeMode, ...modules]"
    :slides-per-view="thumbsSlidesItems"
    :space-between="30"
    @swiper="setThumbsSwiper"
  >
    <swiper-slide
      v-for="(item, index) in itemsForSlide"
      :key="typeof item === 'object' && 'ID' in item ? item.ID : `${item}-${index}`"
    >
      <slot
        v-if="$slots.thumbsIndexedItem"
        name="thumbsIndexedItem"
        v-bind="{ item: item, index: index }"
      />
      <slot
        v-else-if="$slots.item"
        name="item"
        v-bind="item"
      />
      <slot
        v-else-if="$slots.indexedItem"
        name="indexedItem"
        v-bind="{ item: item, index: index }"
      />
    </swiper-slide>
  </swiper>
</template>

<script setup lang="ts" generic="T">
import type {
  AutoplayOptions,
  CoverflowEffectOptions,
  FreeModeOptions,
  NavigationOptions,
  PaginationOptions,
  ScrollbarOptions,
  SwiperModule,
  SwiperOptions,
  Swiper as SwiperType,
  ThumbsOptions
} from 'swiper/types'

import { WindowSize } from '@arora/common'

// Import Swiper Vue.js components
import {
  Autoplay,
  EffectCoverflow,
  FreeMode,
  Navigation,
  Pagination,
  Scrollbar,
  Thumbs
} from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-coverflow'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/thumbs'

type breakpointType = {
  [width: number]: SwiperOptions
  [ratio: string]: SwiperOptions
}

const props = withDefaults(
  defineProps<{
    items: T[]
    autoHeight?: boolean
    autoplayDelay?: number
    slideClick?: (index: number) => void
    centered?: boolean
    className?: string
    disableBreakpoints?: boolean
    effect?: 'coverflow' | 'slide'
    effectModifier?: number
    enableFreeMode?: boolean
    enableNavigation?: boolean
    enableScrollbar?: boolean
    horizontal?: boolean
    initialSlide?: number
    loop?: boolean
    maxItems?: number | 'auto'
    minItems?: number
    paginationType?: 'bullets' | 'dynamic-bullets' | 'fraction' | 'none'
    showPartOfNextSlide?: boolean
    spaceBetween?: number
    thumbsSlidesItems?: number
  }>(),
  {
    autoHeight: false,
    autoplayDelay: 0,
    centered: false,
    className: 'v-swiper',
    disableBreakpoints: false,
    effect: 'slide',
    effectModifier: 1,
    enableFreeMode: false,
    enableNavigation: false,
    enableScrollbar: false,
    horizontal: true,
    initialSlide: 0,
    loop: false,
    maxItems: 4,
    minItems: 1,
    paginationType: 'dynamic-bullets',
    showPartOfNextSlide: true,
    spaceBetween: 15,
    slideClick: () => {
      return
    },
    thumbsSlidesItems: 0
  }
)

const emit = defineEmits({
  loaded: null
})

const uid = useId()

const appConfig = useAppConfig()

const swiperReference = ref<SwiperType>()
const thumbsSwiper = ref<SwiperType>()

const setSwiperReference = (swiper: SwiperType): void => {
  swiperReference.value = swiper
}

const setThumbsSwiper = (swiper: SwiperType): void => {
  thumbsSwiper.value = swiper
}
const {
  autoplayDelay,
  disableBreakpoints,
  effect,
  enableFreeMode,
  enableNavigation,
  enableScrollbar,
  items,
  loop,
  maxItems,
  minItems,
  paginationType,
  showPartOfNextSlide,
  spaceBetween,
  thumbsSlidesItems
} = toRefs(props)

const activeIndex = defineModel('activeIndex', { type: Number, default: 0 })

const freeMode: FreeModeOptions = {
  enabled: true,
  sticky: true
}

const coverflow: CoverflowEffectOptions = {
  rotate: 0,
  stretch: 10,
  depth: 100,
  modifier: 1,
  scale: 0.75,
  slideShadows: false
}

const scrollbar: ScrollbarOptions = {
  draggable: true,
  hide: false
}

const navigation: NavigationOptions = {
  enabled: true,
  nextEl: `.${uid}-swiper-button-next`,
  prevEl: `.${uid}-swiper-button-prev`
}
const pagination: PaginationOptions = {
  clickable: true,
  dynamicBullets: paginationType.value === 'dynamic-bullets',
  el: `#${uid}-v-pagination-swiper`,
  type: paginationType.value === 'fraction' ? 'fraction' : 'bullets',
  bulletActiveClass:
    paginationType.value === 'dynamic-bullets'
      ? 'swiper-pagination-bullet-active'
      : 'swiper-pagination-bullet-active swiper-pagination-bullet-active--non-dynamic'
}

const thumbs = computed<ThumbsOptions | undefined>(() =>
  thumbsSlidesItems.value > 0
    ? { swiper: thumbsSwiper.value, multipleActiveThumbs: false, autoScrollOffset: 1 }
    : undefined
)

// install Swiper modules
const modules = computed<SwiperModule[]>(() => {
  const result: SwiperModule[] = []

  if (autoplayDelay?.value > 0) {
    result.push(Autoplay)
  }

  if (enableNavigation?.value) {
    result.push(Navigation)
  }

  if (paginationType.value !== 'none') {
    result.push(Pagination)
  }

  if (effect.value === 'coverflow') {
    result.push(EffectCoverflow)
  }

  if (enableScrollbar.value) {
    result.push(Scrollbar)
  }

  if (thumbsSlidesItems.value > 0) {
    result.push(Thumbs)
  }

  if (enableFreeMode.value) {
    result.push(FreeMode)
  }

  return result
})

const autoplay = computed<AutoplayOptions>(() => {
  return {
    delay: autoplayDelay.value,
    disableOnInteraction: false
  }
})

const breakpoints = computed<breakpointType | undefined>(() => {
  if (
    disableBreakpoints.value ||
    maxItems.value === 'auto' ||
    maxItems.value <= 1 ||
    typeof maxItems.value === 'string'
  )
    return undefined

  const addition = showPartOfNextSlide.value ? 0.2 : 0
  const result: breakpointType = {}

  const cardWidth = WindowSize.xl / maxItems.value

  for (const [, value] of Object.entries(WindowSize)) {
    const valueNumber = Number(value)
    if (!Number.isNaN(valueNumber)) {
      if (valueNumber === 0) {
        result[valueNumber] = {
          slidesPerView: minItems.value + addition
        }
      } else {
        const current = Math.floor(
          Math.min(valueNumber, appConfig.VueSettingsPreRun.Theme.ContainerWidth) / cardWidth
        )
        result[valueNumber] = {
          slidesPerView: (current < minItems.value ? minItems.value : current) + addition
        }
      }
    }
  }

  return result
})

/* eslint-disable @typescript-eslint/no-explicit-any */
const itemsForSlide = ref<any[]>([])

watch(
  () => items.value,
  (newState: any[] | undefined) => {
    if (newState) {
      itemsForSlide.value = [...newState]
      if (swiperReference.value) swiperReference.value.update()
    }
  },
  { immediate: true, deep: true }
)
/* eslint-enable @typescript-eslint/no-explicit-any */

onMounted(() => {
  itemsForSlide.value = [...items.value]
  emit('loaded')

  if (swiperReference.value)
    setTimeout(() => {
      swiperReference.value!.update()

      if (activeIndex.value > 0) {
        if (loop.value) {
          swiperReference.value!.slideToLoop(activeIndex.value)
        } else {
          swiperReference.value!.slideTo(activeIndex.value)
        }
      }
    }, 350)
})

const isDragging = ref<boolean>(false)

function slideChange(): void {
  if (!isDragging.value) {
    const index = swiperReference.value?.realIndex ?? 0

    if (activeIndex.value !== index) activeIndex.value = index
  }
}

function touchStart(): void {
  isDragging.value = true
}

function touchEnd(): void {
  isDragging.value = false
}

watch(
  () => activeIndex.value,
  (newValue, oldValue) => {
    if (!swiperReference.value) return

    const index = swiperReference.value.realIndex ?? 0
    if (newValue === oldValue || newValue === index) return

    if (loop.value) {
      swiperReference.value.slideToLoop(newValue)
    } else {
      swiperReference.value.slideTo(newValue)
    }
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-swiper-wrapper {
  --swiper-scrollbar-drag-bg-color: var(--ScrollColor, #ececee);
  --swiper-scrollbar-bg-color: var(--ScrollBackgroundColor, #f4f4f4);
  --swiper-scrollbar-bottom: 0px;
  --swiper-scrollbar-sides-offset: 0px;
  --swiper-pagination-bullet-inactive-color: var(--BodyTextColorLight, #a3a3a3);

  position: relative;
  .swiper-pagination {
    z-index: 2;

    .swiper-pagination-bullet-active--non-dynamic {
      width: 32px;
      border-radius: 25px;
    }
  }
}

@include mixins.swiper-pagination-color;
.v-pagination-swiper {
  margin: 0 auto;
}

.swiper-wrapper {
  padding-bottom: 0.5rem;
}

.v-swiper {
  .swiper-wrapper {
    margin-bottom: -15px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-slide {
    transform: none; //swiper puts "transform: translateZ(0);" here
    //That breaks popper no matter how much z-index you put there.
    //Making "transform: none;" here doesn't break anything
    //because we have no 3d animations
  }
}

.v-swiper-thumbs {
  .swiper-slide-thumb-active {
    .v-img-fluid {
      border: 4px solid variables.$PrimaryBackgroundColor;
    }
  }

  .swiper-slide {
    cursor: pointer;
    user-select: none;

    .v-img-fluid {
      border-radius: variables.$BorderRadius;
    }
  }
}
</style>
