@@ -1,75 +0,0 @@
<i18n>
ru:
  birthday: Укажите реальную дату рождения
  connectionTooSlow: Не удалось запросить данные. Проверьте ваше интернет-соединение или попробуйте снова.
  date: Некорректная дата
  digits: 'Пожалуйста, вводите только цифры'
  email: 'Пожалуйста, введите корректный адрес электронной почты'
  intMinMax: 'Пожалуйста введите число между {from} и {to}'
  length: 'Пожалуйста, введите не меньше {min} и не больше {max} символов'
  lengthCode: 'Длина кода должна составлять от {min} до {max} символов'
  lengthMax: 'Пожалуйста, введите не более {max} символов'
  mustHaveNoSpaces: Поле не должно содержать пробелов
  password: 'Проверьте, что новый пароль содержит одну заглавную букву и одну цифру,
    а его длина составляет 6 символов'
  phoneLength: 'Убедитесь, что вы ввели номер полностью'
  required: Это поле необходимо заполнить
  restName: ''
  select: Некорректное значение
  text: Некорректный текст
ua:
  birthday: Вкажіть реальну дату народження
  connectionTooSlow: "Не вдалося отримати дані. Перевірте своє інтернет-з'єднання або спробуйте ще раз."
  date: Некоректна дата
  digits: Значення повинно бути числм
  email: Некоректна адреса електронної пошти
  intMinMax: 'Будь ласка введіть число між {from} і {to}'
  length: 'Будь ласка, введіть не менше {min} і не більше {max} символів'
  lengthCode: 'Довжина коду повинна становити від {min} до {max} символів'
  lengthMax: 'Будь ласка, введіть не більше {max} символів'
  mustHaveNoSpaces: Поле не повинно містити пробіл
  password: 'Переконайтеся, що новий пароль містить одну велику літеру і одну цифру,
    а його довжина становить 6 символів'
  phoneLength: 'Переконайтеся, що ви ввели номер повністю'
  required: Поле не заповнено
  restName: ''
  select: Некоректне значення
  text: Некоректний текст
us:
  birthday: Specify the actual date of birth
  connectionTooSlow: Failed to fetch data. Please check your internet connection or try again.
  date: Invalid date
  digits: Please enter only digits
  email: Please enter a valid email address
  intMinMax: 'Please enter value between {from} and {to}'
  length: 'Please enter at least {min} and no more than {max} characters'
  lengthCode: 'The code length should be from {min} to {max} characters'
  lengthMax: 'Please enter no more than {max} characters'
  mustHaveNoSpaces: Field must not contain spaces
  password: 'Make sure the new password contains one uppercase letter and one digit,
    and its length is 6 characters'
  phoneLength: Make sure you have entered phone number in full
  required: This field is required
  restName: ''
  select: This is not a valid value
  text: Not valid text
</i18n>

<template>
  <cart-notifications />
  <lazy-clientside-check-bonus-system />
  <lazy-clientside-check-terminals />
  <lazy-clientside-cookie-notificator v-if="appConfig.VueSettingsPreRun.EnablePersonalDataWarning" />
  <lazy-clientside-snow v-if="appConfig.VueSettingsPreRun.SnowEnabled" />
  <clientside-get-parameters-in-url />
  <lazy-clientside-scroll-to-top v-if="appConfig.VueSettingsPreRun.ShowScrollToTopButton" />
  <div class="v-position-fixed--right-bottom">
    <integrations-chat-webim v-if="appConfig.RestaurantSettingsPreRun.WebimEnabled" />
    <menu-gifts-ranged-floating />
  </div>
</template>

<script setup lang="ts">
import { useValidationStore } from '@arora/common'
import { getActivePinia } from 'pinia'
const appConfig = useAppConfig()
const menuStore = useMenuStore()
const validationStore = useValidationStore(getActivePinia())

const { translate } = useI18nSanitized()
const activityCheckTimeout = 5 * 60 * 1000

onMounted(() => {
  setInterval(() => {
    menuStore.initActivityMap()
  }, activityCheckTimeout)

  validationStore.ErrorColor = appConfig.VueSettingsPreRun.Theme.ErrorColor
  validationStore.validatorTexts.set('birthday', translate('general.birthday'))
  validationStore.validatorTexts.set('date', translate('general.date'))
  validationStore.validatorTexts.set('digits', translate('general.digits'))
  validationStore.validatorTexts.set('email', translate('general.email'))
  validationStore.validatorTexts.set('noSpace', translate('general.mustHaveNoSpaces'))
  validationStore.validatorTexts.set('password', translate('general.password'))
  validationStore.validatorTexts.set('phone', translate('general.phoneLength'))
  validationStore.validatorTexts.set('select', translate('general.select'))
  validationStore.validatorTexts.set('selzero', translate('general.select'))
  validationStore.validatorTexts.set('text', translate('general.text'))
  validationStore.validatorTexts.set(
    'length',
    translate('general.lengthMax', {
      max: '#MAX#'
    })
  )
  validationStore.validatorTexts.set(
    'lengthMinMax',
    translate('general.length', {
      min: '#MIN#',
      max: '#MAX#'
    })
  )
  validationStore.validatorTexts.set(
    'lengthCode',
    translate('general.lengthCode', {
      min: '#MIN#',
      max: '#MAX#'
    })
  )
  validationStore.validatorTexts.set(
    'intMinMax',
    translate('general.intMinMax', {
      from: '#MIN#',
      to: '#MAX#'
    })
  )
})
</script>

<style lang="scss">
@use '~/assets/mixins';

.v-position-fixed--right-bottom {
  position: fixed;
  z-index: 20;
  right: 45px;
  bottom: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  @include mixins.sm {
    right: 15px;
    bottom: 110px;
  }
}
</style>
