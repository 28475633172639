<template>
  <Head>
    <Title>{{ title }}</Title>
    <Meta
      :content="title"
      property="og:title"
    />
    <Meta
      v-for="(metaTag, index) of route.meta.MetaTags as MetaTag[]"
      :key="`meta-tag-${index}`"
      :content="metaTag.Content"
      :name="metaTag.Name"
    />
    <Style
      :children="`:root { ${theme}`"
      type="text/css"
    />
  </Head>
  <header-page v-if="!route.meta.HideHeader" />

  <NuxtLoadingIndicator
    :color="appConfig.VueSettingsPreRun.Theme.PrimaryBackgroundColor"
    :height="10"
  />
  <common-breadcrumbs />
  <menu-seo-text
    v-if="additionalSeoText"
    addititional-text
    :text="additionalSeoText"
  />
  <main>
    <NuxtPage :page-key="() => route.fullPath" />
  </main>

  <menu-seo-text
    v-if="seoText"
    is-main-page
    :text="seoText"
  />

  <footer-page v-if="!route.meta.HideFooter" />
  <div
    v-if="isLoading"
    id="v-app-loader"
    style="backdrop-filter: blur(10px)"
  >
    <div class="v-loader-wrapper">
      <arora-loader />
    </div>
  </div>
  <ClientOnly>
    <popup-observer />
    <NuxtRouteAnnouncer />
    <clientside-general />
  </ClientOnly>
</template>

<script setup lang="ts">
import createGlobalTheme from '~api/theme'

import type { ProductInList } from '~types/menuStore'
import type { MetaTag } from '~types/preloadRequests'

import GeneralMetric from '~integrations/metric/general'
import { initWebApp } from '~integrations/webapp/general'

import { type GUID, type ISO, RegionalSettings, useBackendCompatibleGuid } from '@arora/common'
import gsap from 'gsap'
import mitt from 'mitt'

// @ts-expect-error no types for sourcebuster
import sbjs from 'sourcebuster'

const accountStore = useAccountStore()
const addressStore = useAddressStore()
const clientStore = useClientStore()
const restaurantStore = useRestaurantStore()
const menuStore = useMenuStore()
const popupStore = usePopupStore()

const appConfig = useAppConfig()
const nuxtApp = useNuxtApp()
const route = useRoute()
const router = useRouter()
const { translate } = useI18nSanitized()
const { generateGUID } = useBackendCompatibleGuid()

const isLoading = ref<boolean>(true)

nuxtApp.hook('app:mounted', () => {
  gsap.to(`#v-app-loader`, {
    duration: 0.4,
    backdropFilter: 'blur(0px)',
    opacity: 0,
    onComplete: () => {
      isLoading.value = false
    }
  })
})

const theme = ref<string>(createGlobalTheme(appConfig.VueSettingsPreRun, !!route.meta.UseAlternateTheme))

restaurantStore.CurrencySettings = RegionalSettings.get(appConfig.VueSettingsPreRun.Currency as ISO)
const { makeDefaultOption } = useProduct()

const title = computed<string | undefined>(() => {
  if (appConfig.SEOPreloadData.UrlTitleInfo[route.path])
    return appConfig.SEOPreloadData.UrlTitleInfo[route.path].Title

  if (route.meta.Title) return route.meta.Title as string

  switch (route.path) {
    case appConfig.VueSettingsPreRun.Links.AboutLink:
      return translate('defaultTexts.aboutUs')
    case appConfig.VueSettingsPreRun.Links.ActionsLink:
      return translate('defaultTexts.actions')
    case appConfig.VueSettingsPreRun.Links.AgreementLink:
      return translate('defaultTexts.agreement')
    case appConfig.VueSettingsPreRun.Links.ArticlesLink:
      return translate('defaultTexts.articles')
    case appConfig.VueSettingsPreRun.Links.CartFinalStep:
      return translate('defaultTexts.mainPage')
    case appConfig.VueSettingsPreRun.Links.CartFirstStep:
    case appConfig.VueSettingsPreRun.Links.CartSecondStep:
      return translate('defaultTexts.cart')
    case appConfig.VueSettingsPreRun.Links.ContactsLink:
      return translate('defaultTexts.contacts')
    case appConfig.VueSettingsPreRun.Links.DeliveryLink:
      return translate('defaultTexts.delivery')
    case appConfig.VueSettingsPreRun.Links.FeedbackLink:
      return translate('defaultTexts.feedback')
    case appConfig.VueSettingsPreRun.Links.MainLink:
      return translate('defaultTexts.mainPage')
    case appConfig.VueSettingsPreRun.Links.PartnershipLink:
      return translate('defaultTexts.partnership')
    case appConfig.VueSettingsPreRun.Links.PayRulesLink:
      return translate('defaultTexts.paymentRules')
    case appConfig.VueSettingsPreRun.Links.PersonalCabinetLink:
      return translate('defaultTexts.personalCabinet')
    case appConfig.VueSettingsPreRun.Links.PersonalDataLink:
      return translate('defaultTexts.personalData')
    case appConfig.VueSettingsPreRun.Links.PointsLink:
      return translate('defaultTexts.pointsPage')
    case appConfig.VueSettingsPreRun.Links.RestaurantSingleLink:
    case appConfig.VueSettingsPreRun.Links.RestaurantsLink:
      return translate('defaultTexts.restaurants')
    case appConfig.VueSettingsPreRun.Links.VacancyLink:
      return translate('defaultTexts.vacancies')
    default:
      return translate('defaultTexts.mainPage')
  }
})

onBeforeMount(async () => {
  const ssid = useCookie(appConfig.SSIDName, {
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  })

  if (!ssid.value) ssid.value = generateGUID()
  await menuStore.initActivityMap()

  if (import.meta.client) {
    if (route.meta.HideHeader && route.meta.HideFooter) document.body.classList.add('v-fullscreen')

    await accountStore.initProfile()
    sbjs.init({ lifetime: 6 })

    window.emitter = mitt()

    //integrations init
    restaurantStore.Metrics = new GeneralMetric(
      appConfig.CurrentRestaurantId,
      appConfig.RestaurantSettingsPreRun
    )

    await initWebApp()
  }

  for (const product of appConfig.Products) {
    if (!menuStore.SelectedModifiersPerProduct.get(product.ID as GUID))
      menuStore.SelectedModifiersPerProduct.set(product.ID as GUID, {})

    if (Object.keys(product.Options).length > 0) makeDefaultOption(product as ProductInList)
  }
})

onMounted(() => {
  if (import.meta.client) {
    if (accountStore.isLoggedIn) {
      accountStore.loadBalance()
    }

    clientStore.initClientState()
    addressStore.initTerminals()
  }
})

const seoText = ref<string | undefined>(route.meta.SeoText as string | undefined)
const additionalSeoText = ref<string | undefined>(route.meta.AdditionalSeoText as string | undefined)

router.afterEach((to) => {
  seoText.value = to.meta.SeoText as string | undefined
  additionalSeoText.value = to.meta.AdditionalSeoText as string | undefined

  theme.value = createGlobalTheme(appConfig.VueSettingsPreRun, !!to.meta.UseAlternateTheme)

  if (popupStore.popupComponent) {
    popupStore.closePopup()
  }

  if (import.meta.client && route.meta.HideHeader && route.meta.HideFooter) {
    document.body.classList.add('v-fullscreen')
  } else {
    document.body.classList.remove('v-fullscreen')
  }
})
</script>

<style lang="scss">
#v-app-loader {
  position: fixed;
  z-index: 999998;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  .v-loader-wrapper {
    z-index: 999999;

    aspect-ratio: 1/1;
    height: 85vmin;
  }
}

.v-fullscreen {
  background: #222;
}
</style>
